.version {
    position: fixed;
    top: 2px;
    right: 10px;
    z-index: 1;
}
.links-wrapper {
    padding: 25px;
}

.logo-container {
    position: fixed;
    top: 2px;
    right: 40px;
    z-index: 1;
}
.logo-container img {
    width: 53px;
    height: 44.125px;
}
