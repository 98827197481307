@import 'black-dashboard-pro-react/custom/functions';
@import '~bootstrap/scss/functions';

@import 'black-dashboard-pro-react/custom/variables';
@import '~bootstrap/scss/variables';

@import 'black-dashboard-pro-react/custom/mixins';
@import '~bootstrap/scss/mixins';

// Bootstrap components

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

// Custom components

@import 'black-dashboard-pro-react/custom/reboot.scss';
@import 'black-dashboard-pro-react/custom/global.scss';
@import 'black-dashboard-pro-react/custom/utilities.scss';
@import 'black-dashboard-pro-react/custom/alerts.scss';
@import 'black-dashboard-pro-react/custom/avatars.scss';
@import 'black-dashboard-pro-react/custom/badge.scss';
@import 'black-dashboard-pro-react/custom/buttons.scss';
@import 'black-dashboard-pro-react/custom/close.scss';
@import 'black-dashboard-pro-react/custom/custom-forms.scss';
@import 'black-dashboard-pro-react/custom/dropdown.scss';
@import 'black-dashboard-pro-react/custom/footer.scss';
@import 'black-dashboard-pro-react/custom/forms.scss';
@import 'black-dashboard-pro-react/custom/grid.scss';
@import 'black-dashboard-pro-react/custom/icons.scss';
@import 'black-dashboard-pro-react/custom/images.scss';
@import 'black-dashboard-pro-react/custom/input-group.scss';
@import 'black-dashboard-pro-react/custom/info-areas.scss';
@import 'black-dashboard-pro-react/custom/modal.scss';
@import 'black-dashboard-pro-react/custom/nav.scss';
@import 'black-dashboard-pro-react/custom/navbar.scss';
@import 'black-dashboard-pro-react/custom/pagination.scss';
@import 'black-dashboard-pro-react/custom/tabs.scss';
@import 'black-dashboard-pro-react/custom/pills.scss';
@import 'black-dashboard-pro-react/custom/popover.scss';
@import 'black-dashboard-pro-react/custom/progress.scss';
@import 'black-dashboard-pro-react/custom/type.scss';
@import 'black-dashboard-pro-react/custom/tables';
@import 'black-dashboard-pro-react/custom/checkboxes-radio';
@import 'black-dashboard-pro-react/custom/fixed-plugin';
@import 'black-dashboard-pro-react/custom/sidebar-and-main-panel.scss';
@import 'black-dashboard-pro-react/custom/misc.scss';
@import 'black-dashboard-pro-react/custom/rtl.scss';
@import 'black-dashboard-pro-react/custom/timeline.scss';

// Vendor / Plugins

@import 'black-dashboard-pro-react/custom/vendor/plugin-datetimepicker.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-nouislider.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-fullcalendar.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-sweetalert2.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-bootstrap-switch.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-bootstrap-select.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-jasny-fileupload.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-tagsinput.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-perfect-scrollbar.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-jquery.jvectormap.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-datatables.net.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-animate-bootstrap-notify.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-card-wizard.scss';
@import 'black-dashboard-pro-react/custom/vendor/plugin-chartjs.scss';

// example pages and sections

@import 'black-dashboard-pro-react/custom/example-pages.scss';

// light mode

@import 'black-dashboard-pro-react/custom/white-content.scss';

// Cards

@import 'black-dashboard-pro-react/custom/card';
@import 'black-dashboard-pro-react/custom/cards/card-chart';
@import 'black-dashboard-pro-react/custom/cards/card-map';
@import 'black-dashboard-pro-react/custom/cards/card-user';
@import 'black-dashboard-pro-react/custom/cards/card-task';
@import 'black-dashboard-pro-react/custom/cards/card-animation-on-hover';
@import 'black-dashboard-pro-react/custom/cards/card-collapse';
@import 'black-dashboard-pro-react/custom/cards/card-contributions';
@import 'black-dashboard-pro-react/custom/cards/card-lock';
@import 'black-dashboard-pro-react/custom/cards/card-pricing';
@import 'black-dashboard-pro-react/custom/cards/card-profile';
@import 'black-dashboard-pro-react/custom/cards/card-plain';
@import 'black-dashboard-pro-react/custom/cards/card-register';
@import 'black-dashboard-pro-react/custom/cards/card-stats';
@import 'black-dashboard-pro-react/custom/cards/card-subcategories';
@import 'black-dashboard-pro-react/custom/cards/card-testimonials';
@import 'black-dashboard-pro-react/custom/cards/card-wizard';

// React Differences
@import 'black-dashboard-pro-react/react/react-differences';

html,
body {
    background-color: #202125 !important;
}

.main-message {
    height: 100vh;
    width: 100vw;
    background-color: #202125;
    font-family: system-ui, sans-serif;
    &__content {
        background-color: #202125;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        .inner {
            background-color: #202125;
            box-sizing: border-box;
            font-size: 1em;
            line-height: 1.6em;
            margin-top: -20%;
            max-width: 600px;
            width: 100%;
            background-repeat: no-repeat;
            background-size: 100%;
            &__title {
                font-weight: 500;
                font-size: 1.6em;
                line-height: 1.25em;
                color: #9aa0a6;
                margin-bottom: 16px;
            }

            &__sub-title {
                font-weight: 500;
                font-size: 1em;
                line-height: 1.6em;
                color: #9aa0a6;
            }

            &__description {
                font-weight: 500;
                font-size: 0.8em;
                text-transform: uppercase;
                margin-top: 12px;
                color: #9aa0a6;
            }
        }
        .control-buttons {
            display: block;
            margin-top: 51px;
            button {
                border: 0;
                border-radius: 4px;
                box-sizing: border-box;
                background: #8ab4f8;
                cursor: pointer;
                float: right;
                font-size: 0.875em;
                margin: 0;
                padding: 8px 16px;
                transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1);
                user-select: none;
                font-family: system-ui, sans-serif;
            }
        }
    }
}

.icon {
    -webkit-user-select: none;
    display: inline-block;
    height: 72px;
    margin: 0 0 40px;
    width: 72px;
    filter: invert(1);
}

.icon-generic {
    /* Can't access chrome://theme/IDR_ERROR_NETWORK_GENERIC from an untrusted
   * renderer process, so embed the resource manually. */
    content: -webkit-image-set(
        url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABIAQMAAABvIyEEAAAABlBMVEUAAABTU1OoaSf/AAAAAXRSTlMAQObYZgAAAENJREFUeF7tzbEJACEQRNGBLeAasBCza2lLEGx0CxFGG9hBMDDxRy/72O9FMnIFapGylsu1fgoBdkXfUHLrQgdfrlJN1BdYBjQQm3UAAAAASUVORK5CYII=)
            1x,
        url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJAAAACQAQMAAADdiHD7AAAABlBMVEUAAABTU1OoaSf/AAAAAXRSTlMAQObYZgAAAFJJREFUeF7t0cENgDAMQ9FwYgxG6WjpaIzCCAxQxVggFuDiCvlLOeRdHR9yzjncHVoq3npu+wQUrUuJHylSTmBaespJyJQoObUeyxDQb3bEm5Au81c0pSCD8HYAAAAASUVORK5CYII=)
            2x
    );
}
